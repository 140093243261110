import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
    providedIn: "root",
})
export class ToastService {
    constructor(public toastController: ToastController) {}

    ngOnInit() {}

    async message(
        message:any,
        type = "primary",
        position = "bottom",
        time = 5000,
        header = "",
        buttons = [
            {
                text: "Fechar",
                role: "cancel",
                handler: () => {},
            },
        ]
    ) {
        const toast = await this.toastController.create({
            message: message,
            color: type,
            header:header,
            position: position as "bottom",
            duration: time,
            buttons: buttons,
        });
        toast.dismiss();
        toast.present();
    }
}
