import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { UserService } from 'src/app/services/user/user.service';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { map, tap, catchError, filter, scan } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public user: UserService,private router: Router) { }



    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.get("skip") == null) {
            let hasToken = false
            let tokenStorage = null;

            return from(Preferences.get({ key: "tokens" })).pipe(switchMap((result: any) => {

                try {
                    result.value = JSON.parse(result.value)
                    if (result.value.access_token != null) {
                        if (!this.tokenExpired(result.value.access_token)) {
                            hasToken = true;
                            tokenStorage = result.value.access_token
                        }
                    }
                } catch (error) {
                    hasToken = false;
                }
                if (hasToken) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${tokenStorage}`
                        }
                    });
                    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            return this.updateResponse(event);
                        }

                        return event;
                    }));
                } else {
                    if(result.value){
                        return this.user.auth().pipe(switchMap((res: any) => {
                            let token = res;
                            Preferences.set({
                                key: "tokens",
                                value: JSON.stringify(token),
                            });
                            request = request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${token.access_token}`
                                }
                            });
                            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                                if (event instanceof HttpResponse) {
                                    return this.updateResponse(event);
                                }

                                return event;
                            }));
                        }));
                    }else{
                        this.router.navigate(["/home"]);
                        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                return this.updateResponse(event);
                            }

                            return event;
                        }));
                    }
                }
            })) as Observable<HttpEvent<any>>;
        } else {
            console.log("não tem");
            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    return this.updateResponse(event);
                }

                return event;
            }));
        }
    }

    private tokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }

    private updateResponse(event: HttpResponse<any>) {
        var decrypted = event.body;
        return new HttpResponse({
          body: decrypted,
          headers: event.headers,
          status: event.status,
          statusText: event.statusText,
          url: event.url
        });
      }
}