import { Injectable } from '@angular/core';
import { StorageUserService } from "src/app/services/user/storage-user.service";
import { Observable, BehaviorSubject,from, ObservableInput } from  'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { env } from "src/app/env";
import { ToastService } from "src/app/services/toast/toast.service";
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class UserService {


    public tokens
    public authSubject = new BehaviorSubject(false);
    someDataObservable: Observable<any>;


    constructor(public http: HttpClient, public httpParams: HttpParams,public storageUser:StorageUserService,private toast:ToastService,private router: Router) {}

    login(data: any) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'skip':'true',
        });
        return this.http.post(env.API_BASE_URL + 'auth', JSON.stringify(data), { headers: headers });
    }

    setUserTokenPush(data: any) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'skip':'true',
        });
        return this.http.post(env.API_BASE_URL + 'pushToken', JSON.stringify(data), { headers: headers });
    }


    auth() {
        const simpleObservable = new Observable((observer) => {
            from(this.storageUser.getTokens()).subscribe((value)=>{
                this.tokens = value;
                if (!this.someDataObservable) {
                    this.someDataObservable = this.http.post(`${env.API_BASE_URL}/auth/refresh`, {'refresh_token':this.tokens.refresh_token},{headers:{skip:"true"}})

                    this.someDataObservable.subscribe(async (res: any) => {
                        if (res.tokens) {
                            observer.next(res.tokens);
                            observer.complete()
                        }
                    },()=>{
                        this.router.navigate(["/home"]);
                    })
                }
            },()=>{
                this.router.navigate(["/home"]);
            })
        })
        return simpleObservable;
    }


    registerStep1(data: any) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'skip':'true',
        });
        return this.http.post(env.API_BASE_URL + 'register/init', JSON.stringify(data), { headers: headers });
    }

    registerStep2(data: any) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'skip':'true',
        });
        return this.http.post(env.API_BASE_URL + 'register/additionalInformations', JSON.stringify(data), { headers: headers });
    }

    sync(uuid:any = '') {
        return this.http.get(env.API_BASE_URL + 'strava/sync/'+uuid);
    }


    getTier(ranking:any) {
        return this.http.get(env.API_BASE_URL + 'tiers/user?ranking='+ranking);
    }


    getTiers() {
        return this.http.get(env.API_BASE_URL + 'tiers');
    }


    getTiersComplete(tierId:any,ranking:any,page:any = 1) {
        return this.http.get(env.API_BASE_URL + 'tiers/'+tierId+'/ranking?ranking='+ranking+'&page='+page);
    }

    getNotifications(page:any = 1){
        return this.http.get(env.API_BASE_URL + 'notifications?page='+page);
    }

    readNotifications(){
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.put(env.API_BASE_URL + 'notifications', '', { headers: headers });
    }

    deleteNotifications(){
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.delete(env.API_BASE_URL + 'notifications');
    }
}
