import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MenuComponent } from "src/app/components/menu/menu.component";

@Component({
    selector: 'app-topbar-header',
    templateUrl: './topbar-header.component.html',
    styleUrls: ['./topbar-header.component.scss'],
})
export class TopbarHeaderComponent implements OnInit {

    @Input() title = '';
    constructor(
        private modalCtrl: ModalController
    ) { }

    ngOnInit() { }

    async openModal() {
        const modal = await this.modalCtrl.create({
          component: MenuComponent,
        });
        modal.present();

        const { data, role } = await modal.onWillDismiss();
    }
}
