<ion-content>
    <div id="header-menu">
        <img src="assets/images/logo-alvo.png" alt="">
        <ion-icon (click)="cancel()" class="button-logout" name="close-outline"></ion-icon>
    </div>
    <div id="items-menu">
        <ion-list class="pt-5">
            <ion-item>
                <ion-label><ion-icon name="person-outline"></ion-icon> Perfil</ion-label>
            </ion-item>
            <ion-item>
                <ion-label (click)="goto('/schedule')"><ion-icon name="calendar-outline"></ion-icon> Agenda</ion-label>
            </ion-item>

            <ion-item>
                <div class="d-flex justify-content-center w-100">
                    <ion-button (click)="logout()" color="danger" shape="round"><ion-icon name="log-out-outline"></ion-icon> Sair</ion-button>
                </div>
            </ion-item>
        </ion-list>
    </div>
</ion-content>
<ion-footer>
    <h6 class="fw-bold text-center">
        Versão: 0.0
    </h6>
</ion-footer>