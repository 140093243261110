import { Component } from '@angular/core';
import { SwUpdate } from "@angular/service-worker";
import { AlertController } from '@ionic/angular';
import { ApiService } from "src/app/services/api/api.service";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { environment } from '../environments/environment';
import {enableProdMode} from '@angular/core';



@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    public appPages = [
        { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
        { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
        { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
        { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
        { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
        { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
    ];
    public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    public updateSubscription;
    constructor(
        private swUpdate: SwUpdate,
        private alertController: AlertController,
        private apiService: ApiService

    ) {
        this.checkForUpdates()
        this.checkInternet()
        defineCustomElements(window);
        if (environment.production) {
            enableProdMode();
        }
    }

    public checkInternet(){
    //     this.apiService.checkConnectionInternet().subscribe((speed)=>{
    //         if(speed<=10){
    //             console.error("Internet Slow: "+ speed+"mpbs")
    //         }
    //         console.log('Your speed is ' + speed);
    //    })
    }

    public checkForUpdates(): void {


        if (this.swUpdate.isEnabled) {
            // Required to enable updates on Windows and ios.
            this.swUpdate.activateUpdate();
            this.promptUser()

            this.swUpdate.checkForUpdate().then(() => {
                console.log('checked for updates');
            });
            setInterval(()=>{
                this.swUpdate.checkForUpdate().then(() => {
                    console.log('checked for updates');
                });
            },10000)
        }
    }


    promptUser(): void {
        this.swUpdate.activateUpdate().then(async () => {
            const alert = await this.alertController.create({
                header: 'Atualização Disponível!',
                message: "Há uma atualização disponível",
                backdropDismiss:false,
                buttons: [
                    {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => {
                            alert.dismiss()
                            window.location.reload();
                        },
                    }
                ],
            });
            await alert.present();

        });
    }
}
