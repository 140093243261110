import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastService } from "src/app/services/toast/toast.service";
import { UserService } from "src/app/services/user/user.service";
import { StorageUserService } from "src/app/services/user/storage-user.service";
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {


    constructor(
        private modalCtrl: ModalController,
        private router: Router,
        private userService: UserService,
        private storageUser: StorageUserService,
    ) {}

    cancel() {
        return this.modalCtrl.dismiss();
    }

    ngOnInit() { }

    goto(route:any){
        console.log(route)
        this.router.navigate([route]);
        this.cancel();
    }

    logout(){
        this.storageUser.removeUser()
        this.router.navigate(["/"]);
    }
}
