import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { IonicModule } from '@ionic/angular';
import { TopbarCarComponent } from "src/app/components/topbar-car/topbar-car.component";
import { TopbarHeaderComponent } from "src/app/components/topbar-header/topbar-header.component";
import { MenuComponent } from "src/app/components/menu/menu.component";
@NgModule({
    imports: [
        CommonModule,
        IonicModule,
    ],
    declarations: [TopbarCarComponent,TopbarHeaderComponent,MenuComponent],
    exports:[
        TopbarCarComponent,
        TopbarHeaderComponent,
        MenuComponent
    ]
})
export class SharedModule { }
