<div id="topo">
    <div class="container">
        <div id="menu-titulo">
            <div class="row">
                <div class="col-3"> <a href="javascript:history.back()"><img alt="" src="assets/images/seta-branca.svg"></a></div>
                <div class="col-8">
                    <h2 class="titulo float-end">{{title}}</h2>
                </div>
                <div class="col-1">
                    <div class="menu float-end" (click)="openModal()">
                        <div id="nav-icon3" class="d-inline">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>