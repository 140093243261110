import { Component, OnInit } from '@angular/core';
import { SwUpdate } from "@angular/service-worker";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertController } from '@ionic/angular';
import { ApiService } from "src/app/services/api/api.service";
import { ToastService } from "src/app/services/toast/toast.service";

@Component({
    selector: 'app-topbar-car',
    templateUrl: './topbar-car.component.html',
    styleUrls: ['./topbar-car.component.scss'],
})
export class TopbarCarComponent implements OnInit {

    public uuid;
    public data;
    constructor(
        private route: ActivatedRoute,
        private apiService: ApiService,
        private router: Router,
        private toast: ToastService,
    ) { }

    ngOnInit() {
        console.log(this.router.url)
        setTimeout(() => {


        this.route.paramMap.subscribe((data: any) => {
            console.log(data.params)
            if (data.params.id != undefined) {
                this.uuid = data.params.id
                this.detailSchedule();
            }
        });
    }, 1000);
    }


    detailSchedule() {
        this.apiService.detailSchedule(this.uuid).subscribe((response: any) => {
            if (response.data.status == 1) {
                this.data = response.data
            }
        }, (error) => {
            if (error.error != undefined) {
                if (error.error.message !== undefined) {
                    this.toast.message(error.error.message, "danger", "top", 10000);
                } else {
                    this.toast.message("Houve um erro de processamento, por favor, tente mais tarde.", "danger", "top");
                }
            }
        });
    }
}
