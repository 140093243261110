<ion-content>
    <div id="block-data-car">
        <div class="row px-2 py-2" *ngIf="data">
            <div class="col-md-12">
                <p class="text-end m-0 fs-6" *ngIf="data.contract.vehicle.plate && data.contract.vehicle.plate != 0">Placa: <small>{{data.contract.vehicle.plate}}</small></p>
                <p class="text-end m-0 fs-6" *ngIf="!data.contract.vehicle.plate || data.contract.vehicle.plate == 0">Chassi: <small>{{data.contract.vehicle.chassi}}</small></p>
            </div>
            <div class="col-md-12">
                <p class="text-end w-100 mt-2" style="font-size: 12px;"><small>{{data.uuid}}</small></p>
            </div>
        </div>
    </div>
</ion-content>