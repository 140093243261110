import { NgModule,LOCALE_ID, isDevMode  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpParams, HTTP_INTERCEPTORS } from "@angular/common/http"
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AuthInterceptor } from "./auth.interceptor";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
registerLocaleData(localeBr, 'pt')
import '@capacitor-community/camera-preview';
import { ServiceWorkerModule } from '@angular/service-worker'
import { SharedModule } from "src/app/shared.module";
import { SpeedTestModule } from 'ng-speed-test';
import { CodeInputModule } from 'angular-code-input';
@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
        HttpClientModule,
        AngularSignaturePadModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        SharedModule,
        SpeedTestModule,
        CodeInputModule
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        HttpParams,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'pt' }

    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
